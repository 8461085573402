*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
body{
    min-height: 130vh;
 }
.container{
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: black;
 }
.container .bubbles{
    position: relative;
    display: flex;
 }
.container .bubbles span{
    position: relative;
    width: 30px;
    height: 30px;
    background:#4fc3dc;
    margin: 0 4px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #4fc3dc44,
    0 0 50px #4fc3dc,
    0 0 100px #4fc3dc;
    animation: animate 15s linear infinite;
    animation-duration: calc(125s / var(--i));

    z-index: 1;
 }
.container .bubbles span:nth-child(even){
    background:#ff2d75;
    box-shadow: 0 0 0 10px #ff2d7544,
    0 0 50px #ff2d75,
    0 0 100px #ff2d75;
    animation: animate 15s linear infinite;
    animation-duration: calc(125s / var(--i));
}
 @keyframes animate {
    0%{
        transform: translateY(100vh) scale(0);
    }
    100%{
        transform: translateY(-10vh) scale(1);
    }
 }
